import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';


export async function getDealIDLoader({ params }: any) {
    const dealId = params.dealId;
    return dealId as string;
}

export const EditDealButton = ({ id }: { id: string }) => {
    const navigate = useNavigate();

    return (
        <IconButton
            onClick={() => navigate(`/deals/${id}`)}
        >
            <EditIcon />
        </IconButton>
    )
}