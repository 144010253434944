import { ArrowBack } from "@mui/icons-material";
import { CircularProgress, IconButton, Paper, Skeleton, Tab, TableContainer, TablePagination, Tabs } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { useGetInvoice, useGetInvoiceDeals } from "./invoiceApi.slice";
import { Invoice } from './types';
import { getDateFormat } from "../../shared/helpers/timeFormat";
import { usePagination } from "../../shared/pagination/usePagination";
import { RequestError } from "../../shared/ui/RequestError";
import { DealListPresenter } from "../deals/ui/DealList/DealList";
import { useState } from "react";
import { InvoiceHistory } from "./InoiceHistory";
import { InvoiceDownloadButton } from "./InvoiceDownloadButton";

enum InvoiceCardWidgets {
    deals = 0,
    history = 1,
}

export function getInvoiceIdLoader({ params }: any) {
    const invoiceId = params.invoiceId;
    return { invoiceId };
}

export const InvoiceCardWidget = () => {
    const { invoiceId } = useLoaderData() as { invoiceId: string };

    const { currentData, isFetching, error } = useGetInvoice(invoiceId);

    return (
        <>
            <IconButton
                onClick={() => {
                    window.history.back();
                }}
            >
                <ArrowBack />
            </IconButton>

            {
                isFetching
                    ? <CircularProgress />
                    : error
                        ? <RequestError error={error} />
                        : currentData
                            ? <InvoiceCard invoice={currentData} />
                            : null
            }
        </>
    )
}

export function InvoiceCard({ invoice }: { invoice: Invoice }) {
    const [widgetIndex, setWidgetIndex] = useState(InvoiceCardWidgets.deals);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }}
        >
            <h2
                style={{
                    'textAlign': 'start'
                }}
            >
                Invoice {invoice.clientCode}
            </h2>
            <h3
                style={{
                    marginTop: '0'
                }}
            >
                {getDateFormat(new Date(invoice.from))} - {getDateFormat(new Date(invoice.to))}
            </h3>
            <div style={{ display: 'flex', gap: '30px' }}>
                <p>Всего сделок: {invoice.invoiceDealsCount}</p>
                <InvoiceDownloadButton invoice={invoice} />
            </div>
            <div>
                <Tabs value={widgetIndex} onChange={(e, newValue) => setWidgetIndex(newValue)}>
                    <Tab label="Сделки" />
                    <Tab label="История" />
                </Tabs>

            </div>
            <div style={{ width: '100%' }}>
                {
                    widgetIndex === InvoiceCardWidgets.deals && <InvoiceDeals invoice={invoice} />
                }
                {
                    widgetIndex === InvoiceCardWidgets.history && <InvoiceHistory history={invoice.historyChanges} />
                }
            </div>
        </div>
    )
}

function InvoiceDeals({ invoice }: { invoice: Invoice }) {
    const pagination = usePagination();
    const { currentData, isFetching, error } = useGetInvoiceDeals({
        id: invoice.id,
        skip: pagination.currentPageNumber * pagination.rowsPerPage,
        count: pagination.rowsPerPage
    });

    return (
        <TableContainer
            component={Paper}
            sx={{
                overflowX: 'auto'
            }}
        >
            {
                isFetching
                    ? <Skeleton variant="rectangular" height={300} />
                    : error
                        ? (<RequestError error={error} />)
                        : <DealListPresenter deals={currentData!} />
            }
            <TablePagination
                count={invoice.invoiceDealsCount}
                page={pagination.currentPageNumber}
                rowsPerPage={pagination.rowsPerPage}
                onPageChange={(e, page) => {
                    if (currentData!.length < pagination.rowsPerPage && page > pagination.currentPageNumber) {
                        return;
                    }
                    pagination.changePageNumber(page);
                }}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => { pagination.changeRowsPerPage(e.target?.value as unknown as number) }}

                style={{
                    'marginLeft': 'auto'
                }}
            />
        </TableContainer>
    )
}