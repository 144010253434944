import { useLoaderData } from "react-router-dom";

import { ClientForm } from "./ClientForm"
import { DeleteClientButton } from "./DeleteClientButton";

import { useChangeClient, useFetchAllClients } from "../clientAPISlice";
import { Metadata } from "../../../api/api.dto";
import { Client } from "../client.dto";
import { ClientCommissions } from "../../commissions/CommissionClientCard";
import { CircularProgress, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { ClientInvoice } from "../../invoices/InvoicesWidget";

export async function getClientIdLoader({ params }: any) {
    const clientCode = params.clientId;
    return clientCode as string;
}

enum ClientWidgetIndex {
    info = 0,
    commission = 1,
    invoice = 2,
}


export const EditClientForm = () => {
    const clientCode = useLoaderData() as string;
    const { data } = useFetchAllClients({});
    const client = data?.find(client => client.code === clientCode);

    const [changeClient, metadata] = useChangeClient();

    const [tab, setTab] = useState(0);


    if (!client) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <>
            <h2>{clientCode}</h2>
            <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} sx={{ marginBottom: '30px' }}>
                <Tab label="Инфо" />
                <Tab label="Комиссии" />
                <Tab label="Инвойсы" />
            </Tabs>
            {
                tab === ClientWidgetIndex.info && (
                    <>
                        <ClientForm
                            client={client}
                            submitFunction={(client: Client) => changeClient({ code: clientCode, client })}
                            metadata={metadata as Metadata}
                        />
                        <DeleteClientButton
                            code={clientCode}
                        />
                    </>
                )
            }
            {
                tab === ClientWidgetIndex.commission && (
                    <ClientCommissions clientCode={client.code} />
                )
            }
            {
                tab === ClientWidgetIndex.invoice && (
                    <ClientInvoice clientCode={client.code} />
                )
            }
        </>
    )
}