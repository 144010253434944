import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../../api/apiHelpers";
import { GetEmployeeResponse } from "./employee.dto";

export const employeeApi = createApi({
    reducerPath: 'employeeApi',
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        getEmployee: builder.query<GetEmployeeResponse, string>({
            query: (id) => `employee/${id}`,
        })
    })
});

export const useGetEmployee = employeeApi.endpoints.getEmployee.useQuery;