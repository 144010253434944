import { Skeleton, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { Invoice, InvoiceHistoryChange } from "./types";
import { useGetEmployee } from "../employee/employee.slice";
import { InvoiceStatusUI } from "./InvoiceStatus";
import { getDateFormat, getTimeFormat } from "../../shared/helpers/timeFormat";

export function InvoiceHistory({ history }:{ history?: Invoice['historyChanges'] }) {

    if (!history) {
        return (<p>Нет истории</p>)
    }

    return (
        <Stepper activeStep={0} orientation="vertical" sx={{ margin: '20px 60px' }}>
            {
                history.map((_, index, array) => <InvoiceHistoryStep step={array[array.length - 1 - index]} key={array.length - 1 - index} />)
            }
        </Stepper>
    )
}

function InvoiceHistoryStep({ step, key }:{ step: InvoiceHistoryChange, key: number }) {
    const { currentData, isFetching } = useGetEmployee(step.changer);

    return (
        <Step key={key} active>
            <StepLabel>
                <InvoiceStatusUI status={step.status} />
            </StepLabel>
            <StepContent>
                <p>{getDateFormat(new Date(step.changedAt))} {getTimeFormat(new Date(step.changedAt))}</p>
                {
                    isFetching
                    ? <Skeleton />
                    : <p>{currentData?.name} {currentData?.lastName} {currentData?.email}</p>
                }
            </StepContent>
        </Step>
    )
}