import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { EditDealForm } from "../../features/deals/ui/EditDealForm";

export const EditDealWidget = () => {
    return (
        <>
            <IconButton
                onClick={() => {
                    window.history.back();
                }}
            >
                <ArrowBack />
            </IconButton>
            <EditDealForm />
        </>
    )
}