import { ClientForm } from "./ClientForm"
import { useAddNewClient } from "../clientAPISlice";
import { Metadata } from "../../../api/api.dto";


export const AddClientForm = () => {
    const [addClient, metadata] = useAddNewClient();

    return (
        <>
            <h2>Новый клиент</h2>
            <ClientForm
                client={undefined}
                submitFunction={addClient}
                metadata={metadata as Metadata}
            />
        </>
    )
}