import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../../api/apiHelpers";
import { CreateInvoicePayload, CreateInvoiceResponse, GetInvoiceDealsPayload, GetInvoicesPayload, GetInvoicesResponse, Invoice } from "./types";
import { Deal } from "../deals/deal.dto";

export const invoiceApi = createApi({
    reducerPath: 'invoices',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Invoice'],
    endpoints: (builder) => ({
        
        getInvoices: builder.query<GetInvoicesResponse, GetInvoicesPayload>({
            query: (params) => ({
                url: 'invoices',
                params,
            }),
            providesTags: ['Invoice']
        }),

        createInvoice: builder.mutation<CreateInvoiceResponse, CreateInvoicePayload>({
            query: (payload) => ({
                url: 'invoice',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Invoice']
        }),

        getInvoice: builder.query<Invoice, string>({
            query: (id) => ({
                url: `invoice/${id}`
            })
        }),

        downloadInvoice: builder.mutation<File, string>({
            query: (id) => ({
                url: `Invoice/${id}/excel-reconciliation`,
                responseHandler: async (response) => {
                    if (response.ok) {
                        const blob = await response.blob();
                        const file = new File([blob], 'invoice.xlsx', { type: 'octet/stream' });
                        return file;
                    }
                },
            }),
        }),

        getInvoiceDeals: builder.query<Deal[], GetInvoiceDealsPayload>({
            query: (params) => ({
                url: `invoice/${params.id}/deals`,
                params: {
                    skip: params.skip,
                    count: params.count,
                }
            })
        })

    }),
});

export const useGetInvoices = invoiceApi.endpoints.getInvoices.useQuery;
export const useGetInvoice = invoiceApi.endpoints.getInvoice.useQuery;
export const useGetInvoiceDeals = invoiceApi.endpoints.getInvoiceDeals.useQuery;

export const useCreateInvoice = invoiceApi.endpoints.createInvoice.useMutation;
export const useDownloadInvoice = invoiceApi.endpoints.downloadInvoice.useMutation;