import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { EditClientForm } from "../../features/client/ui/EditClientForm";


export const ClientCardWidget = () => {
    return (
        <>
            <IconButton
                onClick={() => {
                    window.history.back();
                }}
            >
                <ArrowBack />
            </IconButton>
            <EditClientForm />
        </>
    )
}