import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authentication/auth.slice";
import brokerReducer from '../features/BrokerInfoCoomponent/model/brokerSlice';
import { clientAPI } from "../features/client/clientAPISlice";
import { dealAPI } from "../features/deals/dealAPISlice";
import { commissionsAPI } from "../features/commissions/commissions.slice";
import { invoiceApi } from "../features/invoices/invoiceApi.slice";
import { employeeApi } from "../features/employee/employee.slice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        broker: brokerReducer,
        [clientAPI.reducerPath]: clientAPI.reducer,
        [dealAPI.reducerPath]: dealAPI.reducer,
        [commissionsAPI.reducerPath]: commissionsAPI.reducer,
        [invoiceApi.reducerPath]: invoiceApi.reducer,
        [employeeApi.reducerPath]: employeeApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(clientAPI.middleware)
            .concat(dealAPI.middleware)
            .concat(commissionsAPI.middleware)
            .concat(invoiceApi.middleware)
            .concat(employeeApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;